import React, {createContext, Dispatch, FC, ReactNode, useContext, useReducer} from 'react';
import {UserInfoData} from './components/Login/LoginStatusView';

export const ACTION_LOGIN = 'LOGIN';
export const ACTION_LOGOUT = 'LOGOUT';

interface AppContextProps {
  userInfoData: UserInfoData | null;
  dispatch: Dispatch<AppReducerAction>;
}

const defaultContext = {
  userInfoData: null,
  dispatch: () => {},
}

export const AppContext = createContext<AppContextProps>(defaultContext);

interface AppProviderProps {
  children: ReactNode;
}

interface AppState {
  userInfoData: UserInfoData | null;
}

interface AppReducerAction {
  type: String;
  payload: any;
}

const reducer = (state: AppState, action: AppReducerAction) => {
  switch (action.type) {
    case ACTION_LOGIN:
      return {userInfoData: action.payload};
    case ACTION_LOGOUT:
      return {userInfoData: null};
    default:
      return state;
  }
};

export const AppProvider: FC<AppProviderProps> = ({children}) => {
  const [state, dispatch] = useReducer(reducer, {
    userInfoData: null,
  });

  return <AppContext.Provider value={{...state, dispatch}}>{children}</AppContext.Provider>;
};

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext can only be used within an AppProvider');
  }
  return context;
};
