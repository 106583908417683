import {divIcon, MarkerCluster} from 'leaflet';
import React from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';

const Cluster = (props: any) => {
  return <MarkerClusterGroup
    onClusterMouseOver={() => {
    }}
    maxClusterRadius={10}
    showCoverageOnHover={false}
    zoomToBoundsOnClick={false}
    // disableClusteringAtZoom={8}
    iconCreateFunction={(cluster: MarkerCluster) => {
      const childCount = cluster.getChildCount();
      const pad = 20;
      const _rad = 10 + (childCount * 10);
      const rad = _rad < 10 ? 10 : _rad;
      const outer = rad + pad; // 50
      const label = `<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 ${outer * 2} ${outer * 2}"
            class="node-svg"
          >
            <circle 
              cx="${outer}" 
              cy="${outer}" 
              r="${rad}"
              fill="#9999FF" 
              opacity="0.5" 
              stroke-opacity="0.5"
              stroke-width="${rad / 6}" 
              stroke="#FFFFFF"
            >
            </circle>
            <text
              text-anchor="middle"
              dominant-baseline="middle"
              x="${outer}" 
              y="${outer}" 
              color="#FFFFFF" 
              font-size="${outer / 2}"
             >
              ${childCount}
            </text>
          </svg>`;
      const _size = rad * 2;
      const size = _size < pad ? pad : _size > 50 ? 50 : _size;
      return divIcon({
        className: 'divicon',
        html: label,
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
      });
    }}
    {...props}
  />;
};

export default Cluster;
