import {Button, FormControlLabel, Grid} from '@material-ui/core';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import * as React from 'react';
import {CreateNetworkInput, Network, UpdateNetworkInput} from '../../graphql';
import {cleanFormValues, fieldProps} from './Formik';
import {FormikMaterialCheckbox} from './FormikMaterialCheckbox';
import {FormikMaterialTextField} from './FormikMaterialTextField';

interface NetworkFormProps {
  onSubmit: Function;
  network?: Network;
}

type Values = Omit<CreateNetworkInput, 'clientMutationId'> | Omit<UpdateNetworkInput, 'clientMutationId'>;

const initialValues: Values = {
  name: '',
  enabled: false,
};

export const NetworkForm = (props: NetworkFormProps) => {
  const values = cleanFormValues(props.network, initialValues) as Values;
  return (
    <Formik
      initialValues={props.network ? values : initialValues}
      onSubmit={(
        values: Values,
        {setSubmitting}: FormikHelpers<Values>
      ) => {
        props.onSubmit(values);
        setSubmitting(false);
      }}
    >
      <Form>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Field {...fieldProps('name')} component={FormikMaterialTextField}/>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label="Enabled"
              control={
                <Field name="enabled" component={FormikMaterialCheckbox}/>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row-reverse" alignItems="flex-end">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Form>
    </Formik>
  );
};
