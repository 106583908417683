import {makeStyles} from '@material-ui/core/styles';
import {TileLayer, ZoomControl} from 'react-leaflet';
import {useStationListQuery} from '../../graphql';
import Map from '../Map';
import Cluster from './Cluster';
import NodeLayer from './NodeLayer';
import {StationCardOverlay} from './StationCardOverlay';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRedo} from '@fortawesome/free-solid-svg-icons';
import {MapButton} from './MapButton';
import {LatLngBounds} from 'leaflet';
import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {LayerContext} from './LayerContext';

const useStyles = makeStyles((theme) => ({
  map: {
    // flex: 1,
    // margin: -theme.spacing(2),
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    // height: 800,
    // position: 'absolute',
    // height: '30%',
    // bottom: 0,
    // zIndex: 1024,
  },
  pad20: {
    marginBottom: 20,
  },
  nodeInfo: {
    margin: 10,
    minWidth: 300,
    maxWidth: 500,
    minHeight: 50,
    position: 'absolute',
    zIndex: 1023,
  },
  overlays: {
    position: 'absolute',
    // display: 'flex',
    // flexWrap: 'wrap',
  }
}));

const REFRESH_INTERVAL = 5000;

const NetworkMap = () => {
  const classes = useStyles();
  const [stationListResult, reexecuteQuery] = useStationListQuery();
  const {data, fetching, error} = stationListResult;
  let ref = useRef<NodeJS.Timeout>(null);
  const {setNode} = useContext(LayerContext);

  const refresh = useCallback(() => {
    reexecuteQuery({requestPolicy: 'network-only'});
  }, [reexecuteQuery]);

  useEffect(() => {
    if (ref.current) {
      clearTimeout(ref.current);
    }
    ref.current = setTimeout(refresh, REFRESH_INTERVAL);
    return () => {
      clearTimeout(ref.current);
    };
  }, [refresh]);

  const bounds = !data ? undefined : new LatLngBounds(data.stations.slice(0, 999)
    .filter((n) => n && !!n.latitude && !!n.longitude)
    .map((n) => [n.latitude, n.longitude]));

  return (
    <>
      <MapButton onClick={refresh}>
        <FontAwesomeIcon icon={faRedo} spinPulse={fetching}/>
      </MapButton>

      <StationCardOverlay/>

      <Map
        className={classes.map}
        center={[-34.9, 138.7]}
        bounds={bounds}
        zoom={9}
        preferCanvas={true}
        onmoveend={(e) => console.log(e)}
        onzoomend={(e) => console.log(e)}
        onclick={(e) => setNode(null)}
      >

        <TileLayer
          attribution='&copy; <a href="//osm.org/copyright">OpenStreetMap</a> contributors'
          url="//{s}.tile.osm.org/{z}/{x}/{y}.png"
        />

        <Cluster>
          <NodeLayer data={data?.stations || []}/>
        </Cluster>

        <ZoomControl position="bottomright"/>

      </Map>
    </>
  );
};

export default NetworkMap;
