import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPerson} from '@fortawesome/free-solid-svg-icons';
import useFetch from 'react-fetch-hook';
import {ACTION_LOGIN, useAppContext} from '../../AppContext';

export const apiUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3000/graphql';
const ssoLogoutUrl = process.env.REACT_APP_SSO_LOGOUT_URL ?? '/';
const baseUrl = new URL(apiUrl);
export const loginUrl = baseUrl.origin + '/oauth2/start';
export const logoutUrl = baseUrl.origin + '/oauth2/sign_out?rd=' + ssoLogoutUrl;
export const userinfoUrl = baseUrl.origin + '/oauth2/userinfo';

export const Styles = makeStyles({
  loginStatus: {
    fontSize: 'smaller',
    color: 'whitesmoke',
    textAlign: 'right',
    margin: 10,
  },
  pointer: {
    cursor: 'pointer',
  }
});

export interface UserInfoData {
  user: String;
  email: String;
  groups: Array<String>;
  preferredUsername: String;
}


export const login = () => {
  const url = new URL(loginUrl);
  url.searchParams.set('rd', window.location.href);
  window.location.replace(url);
};

export const logout = () => {
  window.location.replace(logoutUrl);
};

export const LoginStatusView = () => {
  const classes = Styles();
  const {isLoading, error, data} = useFetch<UserInfoData>(userinfoUrl);
  const {dispatch} = useAppContext();


  useEffect(() => {
    if (error?.status === 401) {
      setTimeout(login, 500);
    } else {
      dispatch({type: ACTION_LOGIN, payload: data});
    }
  }, [data, dispatch, error]);

  const content = !data?.preferredUsername ? <span onClick={login} className={classes.pointer}>Login</span> : (
    <>
      <div hidden={!isLoading}>...</div>
      <FontAwesomeIcon icon={faPerson} fixedWidth/>
      {data?.preferredUsername} | <span onClick={logout} className={classes.pointer}>Logout</span>
    </>
  );

  return (
    <>
      <div className={classes.loginStatus}>
        GeniusNav
        {content}
      </div>
    </>
  );
};
