import 'leaflet/dist/leaflet.css';
import React from 'react';
import {Map as LeafletMap, MapProps, ZoomControl} from 'react-leaflet';
import {DefaultLayersControl} from './DefaultLayersControl';

const Map = (props: MapProps) => {

  const defaultLayersControl = props.children ? null : <DefaultLayersControl/>;
  const defaultZoomControl = props.children ? null : <ZoomControl position="bottomright"/>;

  return (
    <LeafletMap
      preferCanvas
      zoomControl={false}
      center={[-34.9, 138.5]}
      zoom={7}
      {...props}
    >

      {defaultLayersControl}
      {defaultZoomControl}

      {props.children}

    </LeafletMap>
  );
};

export default Map;
