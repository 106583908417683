import {Stream, useStreamListQuery} from '../../graphql';
import {ErrorView} from '../GraphQL/ErrorView';
import {StreamListContainer} from './container';
import React, {useEffect} from 'react';

export const StreamListGraphQL = () => {
  const [result, reexecuteQuery] = useStreamListQuery({
    requestPolicy: 'network-only',
  });
  const {data, fetching, error} = result;
  const streams = data?.streams as Array<Stream> ?? [];

  return (
    <ErrorView fetching={fetching} errors={[error]}>
      <StreamListContainer streams={streams}/>
    </ErrorView>
  );
};
