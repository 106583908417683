import {makeStyles} from '@material-ui/core/styles';
import {DivIcon, divIcon, LeafletEvent} from 'leaflet';
import React from 'react';
import {Marker, MarkerProps} from 'react-leaflet';
import {Maybe, Scalars} from '../../graphql';

export interface NodeProps extends MarkerProps {
  id?: string;
  name?: Maybe<Scalars['String']>;
  icon?: DivIcon;
  onMarkerClick?: Function;
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  divIcon: {
    // border: '1px solid red',
  },
  divIconWrap: {
    position: 'relative',
    left: '-100%',
  },
  divIconName: {
    display: 'inline-block',
    minWidth: 20,
    padding: '1px 4px',
    whiteSpace: 'nowrap',
    minHeight: '1em',
    background: 'white',
    textAlign: 'center',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',
  },
  divIconContent: {
    fontSize: '10px',
    margin: 'auto',
  },
}));

export const Node = ({name, ...props}: NodeProps) => {
  const classes = useStyles();

  const pad = 20;
  const ord = 5;
  const _rad = 10 + (ord * 10);
  const rad = _rad < 10 ? 10 : _rad;
  const outer = rad + pad;

  const color = props.color ?? '#FF44FF';

  let label = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 ${outer * 2} ${outer * 2}"        
      >
        <circle 
          cx="${outer}" 
          cy="${outer}" 
          r="${rad}" 
          stroke-width="${rad / 4}" 
          stroke="#FFFFFF"
          fill="${color}"
        />
      </svg>
    `;

  label += `
      <div class="${classes.divIconWrap}">
        <div class="${classes.divIconName}">
          <div class="${classes.divIconContent}">${name}</div>
        </div>
      </div>
    `;

  const _size = rad / pad / 2;
  const size = _size < pad ? pad : _size;
  const icon = divIcon({
    className: classes.divIcon,
    html: label,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
  });

  const markerProps = icon ? {icon, ...props} : props;

  return (
    <Marker
      onClick={(e: LeafletEvent) => props.onMarkerClick && props.onMarkerClick(e)}
      {...markerProps}
    />
  );
};
