import React from 'react';
import {LoginStatusView} from './LoginStatusView';

interface LoginViewProps {
}

export const LoginView = (props: LoginViewProps) => {
  return (
    <>
      <LoginStatusView/>
    </>
  );
};
