import React, {useMemo} from 'react';
import {latLngBounds} from 'leaflet';
import Map from '../Map';
import {DefaultLayersControl} from '../Map/DefaultLayersControl';
import {LayersControl} from 'react-leaflet';
import Cluster from '../NetworkMap/Cluster';
import NodeLayer from '../NetworkMap/NodeLayer';
import {Station} from '../../graphql';
import {makeStyles} from '@material-ui/core/styles';

interface NetworkEditMapProps {
  stations: Array<Station | null>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 400,
  }
}));

export const NetworkEditMap = ({stations}: NetworkEditMapProps) => {
  const classes = useStyles();
  const bounds = useMemo(() => {
    const b = latLngBounds([]);
    stations?.forEach(station => {
      b.extend([station?.latitude, station?.longitude]);
    });
    return b;
  }, [stations]);

  return (
    <Map
      className={classes.root}
      center={[-34.9, 138.7]}
      bounds={stations?.length ? bounds : undefined}
      easeLinearity={100}
      zoom={7}
      maxZoom={12}
    >

      <DefaultLayersControl/>

      <LayersControl position="topright">
        <LayersControl.Overlay name="Stations" checked>
          <Cluster>
            <NodeLayer data={stations}/>
          </Cluster>
        </LayersControl.Overlay>
      </LayersControl>
    </Map>
  );
};
