import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {CreateStationInput, useCreateStationMutation} from '../../graphql';
import {ErrorView} from '../GraphQL/ErrorView';
import {StationNewContainer} from './container';
import {CombinedError} from 'urql';

export const StationNewGraphQL = () => {
  const history = useHistory();
  const [error, setError] = useState<CombinedError | undefined>();
  const [createStationResult, createStationPromise] = useCreateStationMutation();
  const createStation = (values: CreateStationInput) => {
    createStationPromise({input: {...values}}).then((result) => {
      if (result.error) {
        return setError(result.error);
      }
      history.replace('/station/edit/' + result?.data?.createStation?.result?.id);
    });
  }

  return (
    <ErrorView fetching={false} errors={[error]}>
      <StationNewContainer createStation={createStation}/>
    </ErrorView>
  );
};
