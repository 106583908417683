import {Fab, Grid} from '@material-ui/core';
import React from 'react';
import {Link} from 'react-router-dom';
import {Stream} from '../../graphql';
import {CardStyles} from '../Cards/CardStyles';
import {StreamCard} from '../Cards/StreamCard';

interface StreamListViewProps {
  streams: Array<Stream>;
}

export const StreamListView = ({streams}: StreamListViewProps) => {
  const cs = CardStyles();

  return (
    <>

      <Grid container spacing={2} className={cs.root}>
        {streams.map((stream: Stream) => (
          <Grid item md={4} xs={12} key={stream.id}>
            <StreamCard
              stream={stream}
              deleteStream={(id: string) => ''}
            />
          </Grid>
        ))}
      </Grid>

    </>
  );
};
