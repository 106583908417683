import {Station} from '../../graphql';
import {StationListView} from './view';
import React, {useState} from 'react';
import {StationFilterForm, StationFilterFormValues} from '../Form/StationFilterForm';

interface StationListContainerProps {
  stations: Array<Station>;
}

export const StationListContainer = (props: StationListContainerProps) => {
  const [filters, setFilters] = useState<StationFilterFormValues>();

  const stations = !filters ? props.stations : props.stations.filter((v) => {
    const stats = v.stats ? v.stats[v.stats.length - 1] : null;
    if (!filters.disabled && !v.enabled) {
      return false;
    }
    return (
      (
        (filters.offline && (!stats || stats.start === 0)) ||
        (filters.online && (stats && stats.start !== 0))
      )
    );
  });

  return (
    <>
      <div style={{marginBottom: 10}}>
        <StationFilterForm name="StationList" onChange={(values: StationFilterFormValues) => setFilters(values)}/>
      </div>
      <StationListView stations={stations}/>
    </>
  );
};
