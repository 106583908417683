import {faEye, faLocationDot} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, CardActions, CardContent, CardProps, Grid, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Station} from '../../graphql';
import {CardStyles} from './CardStyles';
import clsx from 'clsx';
import React from 'react';
import {formatDistance} from 'date-fns';
import {DataRateChart} from '../Charts/DataRateChart';
import {PositionChart} from '../Charts/PositionChart';
import NetworkMap from '../NetworkMap';
import {StationMap} from '../NetworkMap/StationMap';

interface StationCardProps extends CardProps {
  station?: Station | any;
  deleteStation?: Function;
}

export const StationCard = ({station, deleteStation, ...props}: StationCardProps) => {
  const styles = CardStyles();
  const disabled = !station?.enabled;
  const stats = station.stats ? station.stats[station.stats.length - 1] : null;

  const cls = [
    styles.card,
    // classes.fullwidth,
    disabled ? styles.disabled : '',
  ].join(' ');

  const ago = !stats?.start ? null : formatDistance(new Date(stats?.start), new Date(), {addSuffix: true});
  const color = !stats || stats?.rate === 0 ? '#88888' : '#4444FF';

  const chart = !stats ? null : <PositionChart data={station?.stats ?? []}/>;

  return (
    <Card key={station?.id} className={cls} {...props}>
      <CardContent>

        <Typography className={styles.title}>
          <FontAwesomeIcon icon={faLocationDot} fixedWidth className={styles.icon} color={color}/>
          {station?.name}
        </Typography>

        <Grid container>
          <Grid item xs={6}>

            <Typography className={clsx([styles.medium, styles.pad20])}>
              <FontAwesomeIcon icon={faEye} fixedWidth/> Last seen {stats?.start ? ago : 'never'}
            </Typography>

            <Typography className={styles.medium}>
              Station ID: {stats?.stationId}
            </Typography>
            <Typography className={styles.medium}>
              Antenna: {stats?.antennaDescriptor}
            </Typography>
            <Typography className={styles.medium}>
              Receiver: {stats?.receiverTypeDescriptor}
            </Typography>
            <Typography className={styles.medium}>
              <span className={styles.mr5}>ARP (ECEF):</span>
              <span className={styles.mr5}>{stats?.ecefX.toFixed(2)},</span>
              <span className={styles.mr5}>{stats?.ecefY.toFixed(2)},</span>
              {stats?.ecefZ.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <StationMap station={station} zoom={13}/>
            {/*{chart}*/}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>

          </Grid>
        </Grid>

      </CardContent>

      <CardActions className={styles.actions}>
        {/*<Button size="small" onClick={() => {*/}
        {/*  deleteStation && deleteStation(station);*/}
        {/*}}>{disabled ? 'ENABLE' : 'Disable'}</Button>*/}
        <Link to={`/station/edit/${station?.id}`}>
          <Button size="small">Edit</Button>
        </Link>
      </CardActions>
    </Card>
  );
};
