import {Fab, Grid} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Network} from '../../graphql';
import {CardStyles} from '../Cards/CardStyles';
import {NetworkCard} from '../Cards/NetworkCard';

interface NetworkListViewProps {
  networks: Array<Network>;
}

export const NetworkListView = ({networks}: NetworkListViewProps) => {
  const cs = CardStyles();

  return (
    <>
      <Link to="/network/new" className={cs.fabTop}>
        <Fab size="large" color="primary">Add</Fab>
      </Link>

      <Grid container spacing={2} className={cs.root}>
        {networks.map((network: Network) => (
          <Grid item md={4} xs={12} key={network.id}>
            <NetworkCard
              network={network}
              deleteNetwork={(id: string) => {
              }}/>
          </Grid>
        ))}
      </Grid>
    </>

  );
};
