import React, {HTMLAttributes, ReactElement} from 'react';
import {UniversalCard} from '../Cards/UniversalCard';
import {CombinedError} from 'urql';

interface ErrorViewProps extends HTMLAttributes<HTMLDivElement> {
  fetching: Boolean;
  errors?: Array<CombinedError | undefined>;
  children: ReactElement | Array<ReactElement>;
}

export const ErrorView = (props: ErrorViewProps) => {

  if (props.fetching) {
    return (
      <UniversalCard className={props.className}>
        <>
          Loading...
        </>
      </UniversalCard>
    );
  }

  const errors = !props.errors ? [] : props.errors
    .filter((error) => !!error)
    .map((error) => {
      if (!error) {
        return null;
      }
      const dotIndex = error.message.indexOf("update.");
      if (dotIndex !== -1) {
        return error.message.slice(dotIndex + 7);
      }
      if (error.message.indexOf(':') !== -1) {
        return error.message.split(':').pop();
      }
      return error.message;
    });

  const errorCard = !errors?.length ? null : (
    <UniversalCard className={props.className}>
      <>
        {errors}
      </>
    </UniversalCard>
  );

  return (
    <>
      {errorCard}
      {props.children}
    </>
  );
};
