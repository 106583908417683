import {makeStyles} from '@material-ui/core/styles';

export const CardStyles = makeStyles({
  root: {},
  pad10: {
    marginBottom: 10,
  },
  mr5: {
    marginRight: 5,
  },
  pad20: {
    marginBottom: 20,
  },
  scrollable100: {
    height: 64,
    overflow: 'scroll',
  },
  icon: {
    marginRight: 10,
  },
  fabTop: {
    position: 'absolute',
    right: 20,
    zIndex: 2048,
  },
  disabled: {
    color: '#aaa',
  },
  mono: {
    fontFamily: 'Inconsolata, monospace',
    fontSize: 'smaller',
  },
  card: {
    minHeight: 100,
    // marginBottom: 12,
    // marginRight: 12,
    // minWidth: 250,
    // maxWidth: 400,
    // height: 200,
    // position: 'relative',
    // cursor: 'pointer',
    // '&:hover': {
    //   background: '#fafafa',
    // },
  },
  streamCard: {
    minHeight: 250,
  },
  fullwidth: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
  },
  chip: {
    marginRight: 10,
  },
  medium: {
    fontSize: 10,
  },
  info: {
    fontSize: 8,
  },
  error: {
    color: '#CC0000',
    fontWeight: "bold",
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    // position: 'absolute',
    bottom: 0,
    right: 0,
  }
});
