import {format} from "date-fns";
import {Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {Maybe, StreamStats} from '../../graphql';

interface DataRateChartProps {
  data: Maybe<StreamStats>[];
}

interface ChartStats extends StreamStats {
  epoch: number;
}

export const DataRateChart = ({data}: DataRateChartProps) => {
  let i = 0;
  const chartData = data.map((value: Maybe<StreamStats>) => {
    // Decimate
    if (i++ % 10 !== 0) {
      return null;
    }

    const newValue = value as ChartStats;
    newValue.epoch = (new Date(newValue.start)).getTime();
    newValue.epoch = (new Date(newValue.start)).getTime();
    return newValue;
  }).filter(v => v !== null && v.epoch !== 0);

  const dateFormatter = (date: number) => {
    return format(date, 'HH:mm');
  };

  return (
    <ResponsiveContainer>
    <LineChart
      style={{fontSize: 10, margin: 0}}
      width={100}
      height={100}
      data={chartData as any[]}
    >
      <XAxis height={20} dataKey="epoch" type="number" domain={['dataMin', 'dataMax']} scale="time" tickFormatter={dateFormatter}/>
      <YAxis yAxisId={111} width={25} dataKey="rate" orientation="left"/>
      <YAxis yAxisId={222} width={25} dataKey="numSats" orientation="right"/>
      <Line  yAxisId={111} type="monotone" dataKey="rate" stroke="#4444ff" dot={false}/>
      <Line yAxisId={222}  type="monotone" dataKey="numSats" stroke="#44ff44" dot={false}/>
    </LineChart>
    </ResponsiveContainer>

  );
}
