import {makeStyles} from '@material-ui/core/styles';
import {LayersControl, TileLayer, ZoomControl} from 'react-leaflet';
import Map from '../Map';
import Cluster from './Cluster';
import NodeLayer from './NodeLayer';
import {DefaultLayersControl} from '../Map/DefaultLayersControl';
import React from 'react';
import {Station} from '../../graphql';

const useStyles = makeStyles((theme) => ({
  map: {
    height: 150,
  }
}));

export const StationMap = ({station, zoom}: {station: Station; zoom: number}) => {
  const classes = useStyles();

  return (
    <>
      <Map
        className={classes.map}
        center={[station.latitude, station.longitude]}
        zoom={zoom}
        preferCanvas={true}
        onmoveend={(e) => console.log(e)}
        onzoomend={(e) => console.log(e)}
        dragging={false}
      >
        <TileLayer
          attribution='&copy; <a href="//osm.org/copyright">OpenStreetMap</a>'
          url="//{s}.tile.osm.org/{z}/{x}/{y}.png"
        />

        <NodeLayer data={[station]}/>

      </Map>
    </>
  );
};
