import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Button, Grid} from '@material-ui/core';

interface MenuItemProps {
  path: string;
  text?: string;
  icon?: IconProp;
  onClick?: Function;
}

export const MenuItem = (props: React.PropsWithChildren<MenuItemProps>) => {
  const {icon, onClick, text} = props;
  const listItemIcon = !icon ? null : (
    <FontAwesomeIcon icon={icon} fixedWidth/>
  );

  return (
    <Link to={props.path}
          title={props.text}
          onClick={(e) => onClick ? onClick(e) : null}>
      <Button size="large" style={{width: 80}}>
        <Grid container
              direction="column" alignItems="center" justifyContent="center" alignContent="center">
          {listItemIcon}
          <span style={{fontSize: 10, marginTop: 8}}>{text}</span>
        </Grid>
      </Button>
    </Link>
  );
};
