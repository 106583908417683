import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  Date: any;
  Long: any;
  UUID: any;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  addStations?: Maybe<AddStationsPayload>;
  createNetwork?: Maybe<CreateNetworkPayload>;
  createStation?: Maybe<CreateStationPayload>;
  createStream?: Maybe<CreateStreamPayload>;
  removeStations?: Maybe<RemoveStationsPayload>;
  updateNetwork?: Maybe<UpdateNetworkPayload>;
  updateStation?: Maybe<UpdateStationPayload>;
  updateStream?: Maybe<UpdateStreamPayload>;
};


/** Mutation root */
export type MutationAddStationsArgs = {
  input: AddStationsInput;
};


/** Mutation root */
export type MutationCreateNetworkArgs = {
  input: CreateNetworkInput;
};


/** Mutation root */
export type MutationCreateStationArgs = {
  input: CreateStationInput;
};


/** Mutation root */
export type MutationCreateStreamArgs = {
  input: CreateStreamInput;
};


/** Mutation root */
export type MutationRemoveStationsArgs = {
  input: RemoveStationsInput;
};


/** Mutation root */
export type MutationUpdateNetworkArgs = {
  input: UpdateNetworkInput;
};


/** Mutation root */
export type MutationUpdateStationArgs = {
  input: UpdateStationInput;
};


/** Mutation root */
export type MutationUpdateStreamArgs = {
  input: UpdateStreamInput;
};

export type Network = {
  __typename?: 'Network';
  createdAt?: Maybe<Scalars['Date']>;
  enabled: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  stations?: Maybe<Array<Station>>;
  tenantId?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type NetworkInput = {
  createdAt?: InputMaybe<Scalars['Date']>;
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  stations?: InputMaybe<Array<InputMaybe<StationInput>>>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  network?: Maybe<Network>;
  networks: Array<Network>;
  station?: Maybe<Station>;
  stations: Array<Station>;
  stream?: Maybe<Stream>;
  streamStats?: Maybe<Array<Maybe<StreamStats>>>;
  streams: Array<Stream>;
};


/** Query root */
export type QueryNetworkArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryStationArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryStreamArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryStreamStatsArgs = {
  name: Scalars['String'];
};

export type Station = {
  __typename?: 'Station';
  createdAt?: Maybe<Scalars['Date']>;
  enabled: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  latitude?: Maybe<Scalars['BigDecimal']>;
  longitude?: Maybe<Scalars['BigDecimal']>;
  name?: Maybe<Scalars['String']>;
  networks?: Maybe<Array<Maybe<Network>>>;
  stats?: Maybe<Array<Maybe<StationStats>>>;
  streams?: Maybe<Array<Stream>>;
  tenantId?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type StationInput = {
  createdAt?: InputMaybe<Scalars['Date']>;
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['UUID']>;
  latitude?: InputMaybe<Scalars['BigDecimal']>;
  longitude?: InputMaybe<Scalars['BigDecimal']>;
  name?: InputMaybe<Scalars['String']>;
  networks?: InputMaybe<Array<InputMaybe<NetworkInput>>>;
  streams?: InputMaybe<Array<InputMaybe<StreamInput>>>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};

export type StationStats = {
  __typename?: 'StationStats';
  antennaDescriptor?: Maybe<Scalars['String']>;
  antennaHeight: Scalars['Long'];
  antennaSerialNumber?: Maybe<Scalars['String']>;
  ecefX: Scalars['Long'];
  ecefY: Scalars['Long'];
  ecefZ: Scalars['Long'];
  id?: Maybe<Scalars['UUID']>;
  mountpoint?: Maybe<Scalars['String']>;
  receiverSerialNumber?: Maybe<Scalars['String']>;
  receiverTypeDescriptor?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  stationId: Scalars['Long'];
};

export type Stream = {
  __typename?: 'Stream';
  createdAt?: Maybe<Scalars['Date']>;
  enabled: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  lastDataAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  station?: Maybe<Station>;
  stats?: Maybe<Array<Maybe<StreamStats>>>;
  tenantId?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
};

export type StreamInput = {
  createdAt?: InputMaybe<Scalars['Date']>;
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['UUID']>;
  lastDataAt?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  station?: InputMaybe<StationInput>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  url?: InputMaybe<Scalars['String']>;
};

export type StreamStats = {
  __typename?: 'StreamStats';
  errors?: Maybe<Scalars['String']>;
  formatDetails?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastError?: Maybe<Scalars['String']>;
  mountpoint?: Maybe<Scalars['String']>;
  navSystem?: Maybe<Scalars['String']>;
  numSats: Scalars['Int'];
  rate: Scalars['Int'];
  start?: Maybe<Scalars['Date']>;
};

export type AddStationsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  stationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AddStationsPayload = {
  __typename?: 'addStationsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** Mutation result */
  result?: Maybe<Network>;
};

export type CreateNetworkInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateNetworkPayload = {
  __typename?: 'createNetworkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** Mutation result */
  result: Network;
};

export type CreateStationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  latitude: Scalars['BigDecimal'];
  longitude: Scalars['BigDecimal'];
  name: Scalars['String'];
};

export type CreateStationPayload = {
  __typename?: 'createStationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** Mutation result */
  result: Station;
};

export type CreateStreamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  station: StationInput;
  url: Scalars['String'];
};

export type CreateStreamPayload = {
  __typename?: 'createStreamPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** Mutation result */
  result: Stream;
};

export type RemoveStationsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  stationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RemoveStationsPayload = {
  __typename?: 'removeStationsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** Mutation result */
  result?: Maybe<Network>;
};

export type UpdateNetworkInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type UpdateNetworkPayload = {
  __typename?: 'updateNetworkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** Mutation result */
  result: Network;
};

export type UpdateStationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['UUID'];
  latitude: Scalars['BigDecimal'];
  longitude: Scalars['BigDecimal'];
  name: Scalars['String'];
};

export type UpdateStationPayload = {
  __typename?: 'updateStationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** Mutation result */
  result: Station;
};

export type UpdateStreamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type UpdateStreamPayload = {
  __typename?: 'updateStreamPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  /** Mutation result */
  result?: Maybe<Stream>;
};

export type NetworkListQueryVariables = Exact<{ [key: string]: never; }>;


export type NetworkListQuery = { __typename?: 'Query', networks: Array<{ __typename?: 'Network', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null, stations?: Array<{ __typename?: 'Station', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null, latitude?: any | null, longitude?: any | null }> | null }> };

export type GetNetworkQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetNetworkQuery = { __typename?: 'Query', network?: { __typename?: 'Network', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null, stations?: Array<{ __typename?: 'Station', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null, latitude?: any | null, longitude?: any | null }> | null } | null };

export type CreateNetworkMutationVariables = Exact<{
  input: CreateNetworkInput;
}>;


export type CreateNetworkMutation = { __typename?: 'Mutation', createNetwork?: { __typename?: 'createNetworkPayload', result: { __typename?: 'Network', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null } } | null };

export type UpdateNetworkMutationVariables = Exact<{
  input: UpdateNetworkInput;
}>;


export type UpdateNetworkMutation = { __typename?: 'Mutation', updateNetwork?: { __typename?: 'updateNetworkPayload', result: { __typename?: 'Network', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null } } | null };

export type AddStationsMutationVariables = Exact<{
  input: AddStationsInput;
}>;


export type AddStationsMutation = { __typename?: 'Mutation', addStations?: { __typename?: 'addStationsPayload', result?: { __typename?: 'Network', id?: any | null, name?: string | null, stations?: Array<{ __typename?: 'Station', id?: any | null }> | null } | null } | null };

export type RemoveStationsMutationVariables = Exact<{
  input: RemoveStationsInput;
}>;


export type RemoveStationsMutation = { __typename?: 'Mutation', removeStations?: { __typename?: 'removeStationsPayload', result?: { __typename?: 'Network', id?: any | null, name?: string | null, stations?: Array<{ __typename?: 'Station', id?: any | null }> | null } | null } | null };

export type StationListQueryVariables = Exact<{ [key: string]: never; }>;


export type StationListQuery = { __typename?: 'Query', stations: Array<{ __typename?: 'Station', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null, latitude?: any | null, longitude?: any | null, streams?: Array<{ __typename?: 'Stream', id?: any | null, name?: string | null, enabled: boolean, url?: string | null, createdAt?: any | null, updatedAt?: any | null, station?: { __typename?: 'Station', id?: any | null, enabled: boolean } | null }> | null, stats?: Array<{ __typename?: 'StationStats', id?: any | null, start?: any | null, stationId: any, ecefX: any, ecefY: any, ecefZ: any, antennaHeight: any, antennaSerialNumber?: string | null, antennaDescriptor?: string | null, receiverSerialNumber?: string | null, receiverTypeDescriptor?: string | null } | null> | null }> };

export type GetStationQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetStationQuery = { __typename?: 'Query', station?: { __typename?: 'Station', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null, latitude?: any | null, longitude?: any | null, streams?: Array<{ __typename?: 'Stream', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null, stats?: Array<{ __typename?: 'StreamStats', id?: any | null, start?: any | null, rate: number, numSats: number, formatDetails?: string | null, navSystem?: string | null } | null> | null }> | null, stats?: Array<{ __typename?: 'StationStats', id?: any | null, start?: any | null, stationId: any, ecefX: any, ecefY: any, ecefZ: any, antennaHeight: any, antennaSerialNumber?: string | null, antennaDescriptor?: string | null, receiverSerialNumber?: string | null, receiverTypeDescriptor?: string | null } | null> | null } | null };

export type CreateStationMutationVariables = Exact<{
  input: CreateStationInput;
}>;


export type CreateStationMutation = { __typename?: 'Mutation', createStation?: { __typename?: 'createStationPayload', result: { __typename?: 'Station', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null, latitude?: any | null, longitude?: any | null } } | null };

export type UpdateStationMutationVariables = Exact<{
  input: UpdateStationInput;
}>;


export type UpdateStationMutation = { __typename?: 'Mutation', updateStation?: { __typename?: 'updateStationPayload', result: { __typename?: 'Station', id?: any | null, name?: string | null, enabled: boolean, createdAt?: any | null, updatedAt?: any | null, latitude?: any | null, longitude?: any | null } } | null };

export type StreamListQueryVariables = Exact<{ [key: string]: never; }>;


export type StreamListQuery = { __typename?: 'Query', streams: Array<{ __typename?: 'Stream', id?: any | null, name?: string | null, enabled: boolean, url?: string | null, createdAt?: any | null, updatedAt?: any | null, stats?: Array<{ __typename?: 'StreamStats', id?: any | null, start?: any | null, rate: number, numSats: number, formatDetails?: string | null, navSystem?: string | null, lastError?: string | null } | null> | null }> };

export type GetStreamQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetStreamQuery = { __typename?: 'Query', stream?: { __typename?: 'Stream', id?: any | null, name?: string | null, enabled: boolean, url?: string | null, createdAt?: any | null, updatedAt?: any | null, station?: { __typename?: 'Station', id?: any | null, name?: string | null, enabled: boolean, latitude?: any | null, longitude?: any | null } | null } | null };

export type CreateStreamMutationVariables = Exact<{
  input: CreateStreamInput;
}>;


export type CreateStreamMutation = { __typename?: 'Mutation', createStream?: { __typename?: 'createStreamPayload', result: { __typename?: 'Stream', id?: any | null, name?: string | null, enabled: boolean, url?: string | null, createdAt?: any | null, updatedAt?: any | null, station?: { __typename?: 'Station', id?: any | null } | null } } | null };

export type UpdateStreamMutationVariables = Exact<{
  input: UpdateStreamInput;
}>;


export type UpdateStreamMutation = { __typename?: 'Mutation', updateStream?: { __typename?: 'updateStreamPayload', result?: { __typename?: 'Stream', id?: any | null, name?: string | null, enabled: boolean, url?: string | null, createdAt?: any | null, updatedAt?: any | null, station?: { __typename?: 'Station', id?: any | null } | null } | null } | null };


export const NetworkListDocument = gql`
    query NetworkList {
  networks {
    id
    name
    enabled
    createdAt
    updatedAt
    stations {
      id
      name
      enabled
      createdAt
      updatedAt
      latitude
      longitude
    }
  }
}
    `;

export function useNetworkListQuery(options?: Omit<Urql.UseQueryArgs<NetworkListQueryVariables>, 'query'>) {
  return Urql.useQuery<NetworkListQuery, NetworkListQueryVariables>({ query: NetworkListDocument, ...options });
};
export const GetNetworkDocument = gql`
    query GetNetwork($id: UUID!) {
  network(id: $id) {
    id
    name
    enabled
    createdAt
    updatedAt
    stations {
      id
      name
      enabled
      createdAt
      updatedAt
      latitude
      longitude
    }
  }
}
    `;

export function useGetNetworkQuery(options: Omit<Urql.UseQueryArgs<GetNetworkQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNetworkQuery, GetNetworkQueryVariables>({ query: GetNetworkDocument, ...options });
};
export const CreateNetworkDocument = gql`
    mutation CreateNetwork($input: createNetworkInput!) {
  createNetwork(input: $input) {
    result {
      id
      name
      enabled
      createdAt
      updatedAt
    }
  }
}
    `;

export function useCreateNetworkMutation() {
  return Urql.useMutation<CreateNetworkMutation, CreateNetworkMutationVariables>(CreateNetworkDocument);
};
export const UpdateNetworkDocument = gql`
    mutation UpdateNetwork($input: updateNetworkInput!) {
  updateNetwork(input: $input) {
    result {
      id
      name
      enabled
      createdAt
      updatedAt
    }
  }
}
    `;

export function useUpdateNetworkMutation() {
  return Urql.useMutation<UpdateNetworkMutation, UpdateNetworkMutationVariables>(UpdateNetworkDocument);
};
export const AddStationsDocument = gql`
    mutation AddStations($input: addStationsInput!) {
  addStations(input: $input) {
    result {
      id
      name
      stations {
        id
      }
    }
  }
}
    `;

export function useAddStationsMutation() {
  return Urql.useMutation<AddStationsMutation, AddStationsMutationVariables>(AddStationsDocument);
};
export const RemoveStationsDocument = gql`
    mutation RemoveStations($input: removeStationsInput!) {
  removeStations(input: $input) {
    result {
      id
      name
      stations {
        id
      }
    }
  }
}
    `;

export function useRemoveStationsMutation() {
  return Urql.useMutation<RemoveStationsMutation, RemoveStationsMutationVariables>(RemoveStationsDocument);
};
export const StationListDocument = gql`
    query StationList {
  stations {
    id
    name
    enabled
    createdAt
    updatedAt
    latitude
    longitude
    streams {
      id
      name
      enabled
      url
      createdAt
      updatedAt
      station {
        id
        enabled
      }
    }
    stats {
      id
      start
      stationId
      ecefX
      ecefY
      ecefZ
      antennaHeight
      antennaSerialNumber
      antennaDescriptor
      receiverSerialNumber
      receiverTypeDescriptor
    }
  }
}
    `;

export function useStationListQuery(options?: Omit<Urql.UseQueryArgs<StationListQueryVariables>, 'query'>) {
  return Urql.useQuery<StationListQuery, StationListQueryVariables>({ query: StationListDocument, ...options });
};
export const GetStationDocument = gql`
    query GetStation($id: UUID!) {
  station(id: $id) {
    id
    name
    enabled
    createdAt
    updatedAt
    latitude
    longitude
    streams {
      id
      name
      enabled
      createdAt
      updatedAt
      stats {
        id
        start
        rate
        numSats
        formatDetails
        navSystem
      }
    }
    stats {
      id
      start
      stationId
      ecefX
      ecefY
      ecefZ
      antennaHeight
      antennaSerialNumber
      antennaDescriptor
      receiverSerialNumber
      receiverTypeDescriptor
    }
  }
}
    `;

export function useGetStationQuery(options: Omit<Urql.UseQueryArgs<GetStationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationQuery, GetStationQueryVariables>({ query: GetStationDocument, ...options });
};
export const CreateStationDocument = gql`
    mutation CreateStation($input: createStationInput!) {
  createStation(input: $input) {
    result {
      id
      name
      enabled
      createdAt
      updatedAt
      latitude
      longitude
    }
  }
}
    `;

export function useCreateStationMutation() {
  return Urql.useMutation<CreateStationMutation, CreateStationMutationVariables>(CreateStationDocument);
};
export const UpdateStationDocument = gql`
    mutation UpdateStation($input: updateStationInput!) {
  updateStation(input: $input) {
    result {
      id
      name
      enabled
      createdAt
      updatedAt
      latitude
      longitude
    }
  }
}
    `;

export function useUpdateStationMutation() {
  return Urql.useMutation<UpdateStationMutation, UpdateStationMutationVariables>(UpdateStationDocument);
};
export const StreamListDocument = gql`
    query StreamList {
  streams {
    id
    name
    enabled
    url
    createdAt
    updatedAt
    stats {
      id
      start
      rate
      numSats
      formatDetails
      navSystem
      lastError
    }
  }
}
    `;

export function useStreamListQuery(options?: Omit<Urql.UseQueryArgs<StreamListQueryVariables>, 'query'>) {
  return Urql.useQuery<StreamListQuery, StreamListQueryVariables>({ query: StreamListDocument, ...options });
};
export const GetStreamDocument = gql`
    query GetStream($id: UUID!) {
  stream(id: $id) {
    id
    name
    enabled
    url
    createdAt
    updatedAt
    station {
      id
      name
      enabled
      latitude
      longitude
    }
  }
}
    `;

export function useGetStreamQuery(options: Omit<Urql.UseQueryArgs<GetStreamQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStreamQuery, GetStreamQueryVariables>({ query: GetStreamDocument, ...options });
};
export const CreateStreamDocument = gql`
    mutation CreateStream($input: createStreamInput!) {
  createStream(input: $input) {
    result {
      id
      name
      enabled
      url
      createdAt
      updatedAt
      station {
        id
      }
    }
  }
}
    `;

export function useCreateStreamMutation() {
  return Urql.useMutation<CreateStreamMutation, CreateStreamMutationVariables>(CreateStreamDocument);
};
export const UpdateStreamDocument = gql`
    mutation UpdateStream($input: updateStreamInput!) {
  updateStream(input: $input) {
    result {
      id
      name
      enabled
      url
      createdAt
      updatedAt
      station {
        id
      }
    }
  }
}
    `;

export function useUpdateStreamMutation() {
  return Urql.useMutation<UpdateStreamMutation, UpdateStreamMutationVariables>(UpdateStreamDocument);
};