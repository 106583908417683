import React from 'react';
import {Stream} from '../../graphql';
import {StreamEditView} from './view';

interface StreamEditContainerProps {
  stream: Stream;
  updateStream: Function;
}

export const StreamEditContainer = (props: StreamEditContainerProps) => {
  return <StreamEditView {...props} />;
};
