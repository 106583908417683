import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  QueryStationArgs,
  Station,
  UpdateStationInput,
  useGetStationQuery,
  useUpdateStationMutation
} from '../../graphql';
import {StationEditContainer} from './container';
import {ErrorView} from '../GraphQL/ErrorView';

export const StationEditGraphQL = () => {
  const history = useHistory();
  const {id} = useParams<QueryStationArgs>();
  const [result, reexecuteQuery] = useGetStationQuery({variables: {id}, requestPolicy: 'network-only'});
  const {data, fetching, error} = result;
  const [updateStationResult, updateStationPromise] = useUpdateStationMutation();
  const updateStation = (values: UpdateStationInput) => {
    updateStationPromise({input: {...values, id}}).then((result) => {
      history.push('/stations');
    });
  }
  const stationEditContainer = !data?.station ? <>Station not found</> :
    <StationEditContainer
      station={data.station as Station}
      updateStation={updateStation}
    />;

  return (
    <ErrorView fetching={fetching} errors={[error]}>
      {stationEditContainer}
    </ErrorView>
  );
};
