import {Grid} from '@material-ui/core';
import React from 'react';
import {Maybe, Station, Stream, UpdateStationInput} from '../../graphql';
import {StreamCard} from '../Cards/StreamCard';
import {UniversalCard} from '../Cards/UniversalCard';
import {StationForm} from '../Form/StationForm';
import {StreamNew} from '../StreamNew';
import {CardStyles} from '../Cards/CardStyles';
import {NetworkEditMap} from '../NetworkEdit/map';

interface StationEditViewProps {
  station: Station;
  updateStation: Function;
}

export const StationEditView = ({station, updateStation}: StationEditViewProps) => {
  const styles = CardStyles();
  return (
    <>

      <Grid container spacing={2} className={styles.pad10}>
        <Grid item md={6} xs={12}>
          <UniversalCard title="Edit Station">
            <StationForm station={station} onSubmit={(values: UpdateStationInput) => {
              updateStation(values);
            }}/>
          </UniversalCard>
        </Grid>
        <Grid item md={6} xs={12}>
          <NetworkEditMap stations={[station] || []}/>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={styles.pad10}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2} className={styles.pad10}>
            {station.streams?.map((stream: Maybe<Stream>) => !stream ? <></> : (
              <Grid item xl={6} xs={12} key={stream.id}>
                <StreamCard
                  stream={stream}
                  deleteStream={() => {
                  }}/>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item md={6} xs={12} key={station.id}>
          <StreamNew stationId={station.id} redirectToStation={true}/>
        </Grid>
      </Grid>

    </>
  );
};
