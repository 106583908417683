import {Button, FormControlLabel, Grid} from '@material-ui/core';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {CreateStationInput, Station, UpdateStationInput} from '../../graphql';
import {cleanFormValues, fieldProps} from './Formik';
import {FormikMaterialCheckbox} from './FormikMaterialCheckbox';
import {FormikMaterialNumberField} from './FormikMaterialNumberField';
import {FormikMaterialTextField} from './FormikMaterialTextField';

interface StationFormProps {
  onSubmit: Function;
  station?: Station;
}

type Values = Omit<CreateStationInput, 'clientMutationId'> | Omit<UpdateStationInput, 'clientMutationId'>;

const initialValues: Values = {
  name: '',
  latitude: 0.0,
  longitude: 0.0,
  enabled: false,
};

export const StationForm = (props: StationFormProps) => {
  const values = cleanFormValues(props.station, initialValues) as Values;
  return (
    <Formik
      initialValues={props.station ? values : initialValues}
      onSubmit={(
        values: Values,
        {setSubmitting}: FormikHelpers<Values>
      ) => {
        props.onSubmit(values);
        setSubmitting(false);
      }}
    >
      <Form>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Field {...fieldProps('name')} component={FormikMaterialTextField}/>
          </Grid>

          <Grid item xs={12}>
            <Field {...fieldProps('latitude')} component={FormikMaterialNumberField}/>
          </Grid>
          <Grid item xs={12}>
            <Field {...fieldProps('longitude')} component={FormikMaterialNumberField}/>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label="Enabled"
              control={
                <Field name="enabled" component={FormikMaterialCheckbox}/>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row-reverse" alignItems="flex-end">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Form>
    </Formik>
  );
};
