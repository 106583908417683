import {FormControlLabel, FormGroup, Grid, Switch, Typography} from '@material-ui/core';
import * as React from 'react';
import {PropsWithChildren, SyntheticEvent, useEffect, useState} from 'react';
import {Form, Formik} from 'formik';

export interface StationFilterFormValues {
  disabled: boolean;
  offline: boolean;
  online: boolean;
}

const initialValues: StationFilterFormValues = {
  disabled: false,
  offline: true,
  online: true,
};

interface StationFilterFormProps {
  name: string;
  onChange?: (values: StationFilterFormValues) => void;
  onSubmit?: (values: StationFilterFormValues) => void;
}

export const StationFilterForm = ({name, onChange, onSubmit}: PropsWithChildren<StationFilterFormProps>) => {
  const ls = window.localStorage.getItem('StationFilterForm-' + name);
  const init = ls ? JSON.parse(ls) : initialValues;
  const [values, setValues] = useState<StationFilterFormValues>(init);
  const onSwitchInput = (e: SyntheticEvent<HTMLInputElement>): void => {
    if (e.target instanceof HTMLInputElement) {
      const newValues = {...values, ...{[e.target.name]: e.target.checked}};
      setValues(newValues);
      onChange && onChange(newValues);
      window.localStorage.setItem('StationFilterForm-' + name, JSON.stringify(newValues));
    }
  }

  let once = false;
  useEffect(() => {
    if (!once) {
      onChange && onChange(init);
      once = true; // FIXME
    }
  }, [once]);

  const textProps = {
    style: {fontSize: 14}
  };

  return (
    <Formik
      initialValues={init}
      onSubmit={null}
    >
      {({isValid, isSubmitting, values}) => (
        <Form>
          <FormGroup>
            <Grid container direction="row-reverse" alignItems="center" justifyContent="flex-start">

              <Grid item xs={4} sm={2} xl={1}>
                <FormControlLabel
                  name="disabled" label={<Typography {...textProps}>Show Disabled</Typography>}
                  control={<Switch onInput={onSwitchInput} size="small" defaultChecked={values.disabled}/>}
                />
              </Grid>

              <Grid item xs={4} sm={2} xl={1}>
                <FormControlLabel
                  name="offline" label={<Typography {...textProps}>Show Offline</Typography>}
                  control={<Switch onInput={onSwitchInput} size="small" defaultChecked={values.offline}/>}
                />
              </Grid>

              <Grid item xs={4} sm={2} xl={1}>
                <FormControlLabel
                  name="online" label={<Typography {...textProps}>Show Online</Typography>}
                  control={<Switch onInput={onSwitchInput} size="small" defaultChecked={values.online}/>}
                />
              </Grid>

            </Grid>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};
