import React from 'react';
import {MainLayoutView} from './view';
import {Loading} from './Loading';
import {useAppContext} from '../../AppContext';

interface MainLayoutContainerProps {
  menu?: JSX.Element;
  flex?: Boolean;
}

export const MainLayoutContainer = (props: React.PropsWithChildren<MainLayoutContainerProps>) => {
  const {userInfoData} = useAppContext();
  const loading = userInfoData ? null : <Loading/>;

  return (
    <>
      {loading}
      <MainLayoutView {...props}/>
    </>
  );
};
