import {TextField, TextFieldProps} from '@material-ui/core';
import {FieldProps} from 'formik/dist/Field';

/**
 * Adapts the Material field event handler for use with Formik
 */
export const FormikMaterialTextField = ({field, form, ...props}: FieldProps & TextFieldProps) => {
  return (
    <TextField
      value={form.values[field.name] ?? ''}
      onChange={e => form.setFieldValue(field.name, e.target.value)}
      {...props}
    />
  );
};
