import {TextField, TextFieldProps} from '@material-ui/core';
import {FieldProps} from 'formik/dist/Field';
import {ChangeEvent} from 'react';

/**
 * Adapts the Material field event handler for use with Formik
 */
export const FormikMaterialNumberField = ({field, form, ...props}: FieldProps & TextFieldProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const matches = e.target.value.match(/-?[0-9]+\.?[0-9]*/);
    let value = matches ? matches[0] : '';
    value = e.target.value === '-' ? '-' : value;
    form.setFieldValue(field.name, value);
  };
  return (
    <TextField
      value={form.values[field.name] ?? ''}
      onChange={onChange}
      {...props}
    />
  );
};
