import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNodes} from '@fortawesome/free-solid-svg-icons';
import {Typography} from '@material-ui/core';

export const Styles = makeStyles({
  overlay: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1999,
    background: 'white',
  },
  text: {
    marginTop: 20,
    marginBottom: 20,
  }
});

export const Loading = () => {
  const classes = Styles();

  return (
    <div className={classes.overlay}>
      <Typography className={classes.text}>GENIUS</Typography>
      <FontAwesomeIcon icon={faCircleNodes} size="2x" spin={true}/>
      <Typography className={classes.text}>One moment please..</Typography>
    </div>
  );
}
