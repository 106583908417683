import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {QueryStreamArgs, UpdateStreamInput, useGetStreamQuery, useUpdateStreamMutation,} from '../../graphql';
import {StreamEditContainer} from './container';
import {CombinedError} from 'urql';
import {ErrorView} from '../GraphQL/ErrorView';

export const StreamEditGraphQL = () => {
  const history = useHistory();
  const {id} = useParams<QueryStreamArgs>();
  const [error, setError] = useState<CombinedError | undefined>();
  const [result, reexecuteQuery] = useGetStreamQuery({variables: {id}});
  const {data, fetching, error: getError} = result;
  const [updateStreamResult, updateStreamPromise] = useUpdateStreamMutation();
  const updateStream = (values: UpdateStreamInput) => {
    updateStreamPromise({input: {...values, id}}).then((result) => {
      if (result.error) {
        return setError(result.error);
      }
      history.push('/streams');
    });
  }

  const streamEditContainer = !data?.stream ? <>Stream not found</> :
    <StreamEditContainer
      stream={data.stream}
      updateStream={updateStream}
    />;

  return (
    <ErrorView fetching={fetching} errors={[error, getError]}>
      {streamEditContainer}
    </ErrorView>
  );
};
