import {Card, CardActions, CardContent, CardProps, Typography} from '@material-ui/core';
import React, {ReactElement} from 'react';
import {CardStyles} from './CardStyles';

interface UniversalCardProps extends CardProps {
  body?: string | ReactElement;
  actions?: string | ReactElement;
  children?: ReactElement | Array<ReactElement>;
}

export const UniversalCard = ({title, body, actions, children, ...props}: UniversalCardProps) => {
  const classes = CardStyles();

  return (
    <Card className={`${classes.pad20} ${props.className}`}>
      <CardContent>
        <Typography className={classes.title}>
          {title}
        </Typography>

        <Typography className={classes.pad10} component="div">
          {body}
        </Typography>

        {children}

      </CardContent>

      <CardActions className={classes.actions}>
        {actions}
      </CardActions>
    </Card>
  );
};
