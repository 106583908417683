import * as _ from 'lodash';

export const fieldProps = (name: string, initialValue = '') => ({
  name,
  fullWidth: true,
  label: name.split(/([a-z]+)([A-Z][a-z]+)/).map(n => n.charAt(0).toUpperCase() + n.slice(1)).join(' '),
  variant: 'outlined',
});

export const cleanFormValues = (values: any, keysFrom: object) => {
  return _.mapValues(_.pick(values, Object.keys(keysFrom)), (v: any) => v ?? '');
};
