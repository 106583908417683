import React from 'react';
import {NetworkNewView} from './view';

interface NetworkNewContainerProps {
  createNetwork: Function;
}

export const NetworkNewContainer = (props: NetworkNewContainerProps) => {
  return <NetworkNewView {...props} />;
};
