import {LayersControl, TileLayer} from 'react-leaflet';

export const DefaultLayersControl = () => {
  return (
    <LayersControl position="topright">
      <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
        <TileLayer
          attribution='&copy; <a href="//osm.org/copyright">OpenStreetMap</a> contributors'
          url="//{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="OpenStreetMap.Mapnik" checked>
        <TileLayer
          attribution='&copy; <a href="//osm.org/copyright">OpenStreetMap</a> contributors'
          url="//{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
    </LayersControl>
  );
};
