import React from 'react';
import {MenuItem} from './MenuItem';
import Divider from '@material-ui/core/Divider';
import {faCircleNodes, faGlobe, faHome, faLocationDot, faRightLeft} from '@fortawesome/free-solid-svg-icons';
import {useAppContext} from '../../AppContext';

export const MainMenu = () => {

  const {userInfoData} = useAppContext();

  const menuLogged = !userInfoData ? null :
    <>
      {/*<Divider/>*/}
      <MenuItem path="/networks" icon={faCircleNodes} text="Networks"/>
      <MenuItem path="/stations" icon={faLocationDot} text="Stations"/>
      <MenuItem path="/streams" icon={faRightLeft} text="Streams"/>
      <MenuItem path="/map" icon={faGlobe} text="Map"/>
    </>
  ;

  return (
    <div className="MainMenu">
      {/*<MenuItem path="/" icon={faHome} text="Home"/>*/}
      {menuLogged}
    </div>
  );
};
