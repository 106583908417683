import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import {Container, Grid} from '@material-ui/core';
import {Login} from '../Login';
import {MainMenu} from '../Menu/MainMenu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    minHeight: 50,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // marginLeft: 10,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
    },
},
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      // width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    marginTop: 60,
    height: 'calc(100vh - 60px);',
    [theme.breakpoints.down('xs')]: {
      marginTop: 90,
      height: 'calc(100vh - 90px);',
    },
    overflowY: 'scroll',
    padding: theme.spacing(2),
  },
}));

interface MainLayoutViewProps {
  menu?: JSX.Element;
  flex?: Boolean;
}

export const MainLayoutView = (props: React.PropsWithChildren<MainLayoutViewProps>) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <CssBaseline/>

      <AppBar position="fixed">
        <Grid container direction="row-reverse">
          <Grid item xs={12} sm={4}>
            <Login/>
          </Grid>
          <Grid item xs={12} sm={8}>
            {props.menu}
          </Grid>
        </Grid>
      </AppBar>

      <main
        className={clsx(classes.content, {
          [classes.flex]: props.flex
        })}>
        {props.children}
      </main>

    </div>
  );
};
