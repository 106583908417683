import {LatLng} from 'leaflet';
import React, {Context, useState} from 'react';

interface LayerContextProps {
  point?: any;
  setPoint?: Function;
  node?: string;
  setNode?: Function;
}

const LayerContext: Context<LayerContextProps> = React.createContext({});

interface LayerContextProviderProps {
}

const LayerContextProvider = ({children}: React.PropsWithChildren<LayerContextProviderProps>) => {

  const [point, setPoint] = useState<LatLng>(new LatLng(0, 0));
  const [node, setNode] = useState<string>();

  const defaultValue = {
    point,
    setPoint,
    node,
    setNode
  };

  return (
    <LayerContext.Provider value={defaultValue}>
      {children}
    </LayerContext.Provider>
  );
};

export {LayerContext, LayerContextProvider};
