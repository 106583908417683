import {LeafletEvent} from 'leaflet';
import React, {useContext} from 'react';
import {Maybe, Scalars, StationStats} from '../../graphql';
import {Node} from './Node';
import {LayerContext} from './LayerContext';

interface NodeLayerItem {
  id?: string;
  latitude?: Maybe<Scalars['BigDecimal']>;
  longitude?: Maybe<Scalars['BigDecimal']>;
  name?: Maybe<Scalars['String']>;
  stats?: Maybe<Array<Maybe<StationStats>>>;
}

interface NodeLayerProps {
  data?: Array<NodeLayerItem | null> | null;
}

const NodeLayer = (props: NodeLayerProps) => {
  const {setNode} = useContext(LayerContext);

  const nodes = props.data?.slice(0, 999)
    .filter((n) => n && !!n.latitude && !!n.longitude)
    .map((n) => {
      if (!n) return null;
      const pos = {lat: n.latitude, lng: n.longitude};
      const stats = n.stats ? n.stats[n.stats.length - 1] : null;
      const color = !stats || stats?.start === 0 ? '#88888' : '#4444FF';
      return (
        <Node
          id={n.id}
          key={n.id}
          name={n.name}
          position={pos}
          color={color}
          onMarkerClick={(e: LeafletEvent) => {
            if (typeof setNode === 'function') setNode(n.id);
          }}
        />
      );
    });

  return (
    <>
      {nodes}
    </>
  );
};

export default NodeLayer;
