import {Checkbox, CheckboxProps} from '@material-ui/core';
import {FieldProps} from 'formik/dist/Field';

/**
 * Adapts the Material field event handler for use with Formik
 */
export const FormikMaterialCheckbox = ({field, form, ...props}: FieldProps & CheckboxProps) => {
  return (
    <Checkbox
      checked={!!form.values[field.name]}
      onChange={e => form.setFieldValue(field.name, e.target.checked)}
      {...props}
    />
  );
};
