import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {CreateStreamInput, useCreateStreamMutation} from '../../graphql';
import {StreamNewContainer} from './container';
import {CombinedError} from 'urql';
import {ErrorView} from '../GraphQL/ErrorView';

interface StreamNewProps {
  stationId: string;
  redirectToStation?: boolean;
}

export const StreamNewGraphQL = (props: StreamNewProps) => {
  const history = useHistory();
  const [error, setError] = useState<CombinedError | undefined>();
  const [createStreamResult, createStreamPromise] = useCreateStreamMutation();
  const createStream = (values: CreateStreamInput) => {
    createStreamPromise({
      input: {
        station: {id: props.stationId, enabled: true},
        name: values.name,
        url: values.url
      },
    }).then((result) => {
      if (result.error) {
        return setError(result.error);
      }
      history.replace('/stream/edit/' + result?.data?.createStream?.result?.id);
      if (props.redirectToStation) {
        history.replace('/station/edit/' + props.stationId);
      }
    });
  }

  return (
    <ErrorView fetching={false} errors={[error]}>
      <StreamNewContainer createStream={createStream}/>
    </ErrorView>
  );
};
