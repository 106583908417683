import React from 'react';
import {StationNewView} from './view';

interface StationNewContainerProps {
  createStation: Function;
}

export const StationNewContainer = (props: StationNewContainerProps) => {
  return <StationNewView {...props} />;
};
