import {Button, Card, CardActions, CardContent, Grid, Typography} from '@material-ui/core';
import React from 'react';
import {Stream} from '../../graphql';
import {CardStyles} from './CardStyles';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faRightLeft} from '@fortawesome/free-solid-svg-icons';
import {formatDistance} from 'date-fns';
import clsx from 'clsx';
import {DataRateChart} from '../Charts/DataRateChart';

interface StreamCardProps {
  stream: Stream;
  lastChanged?: string;
  deleteStream: Function;
}

export const StreamCard = ({stream, deleteStream}: StreamCardProps) => {
  const styles = CardStyles();
  const disabled = !stream.enabled;
  const stats = stream.stats ? stream.stats[stream.stats.length - 1] : null;

  const handleDelete = () => {
    deleteStream(stream.id);
  };

  const cls = [
    styles.card,
    // styles.fullwidth,
    styles.streamCard,
    disabled ? styles.disabled : '',
  ].join(' ');

  const ago = !stats?.start ? null : formatDistance(new Date(stats?.start), new Date(), {addSuffix: true});

  const color = !stats || stats?.rate === 0 ? '#88888' : '#4444FF';
  const error = stats?.lastError ? `Error: ${stats?.lastError}` : null;

  const chart = error ? null : <DataRateChart data={stream.stats ?? []}/>;

  const info = !stats || stats?.rate === 0 ?
    <Typography className={clsx([styles.medium, styles.error])}>
      {error}
    </Typography>
    :
    <Grid container>
      <Grid item xs={6}>
        <Typography className={styles.medium}>
          <span style={{color: "#4444ff"}}>Data rate</span> : {stats?.rate ?? 0} KB/sec
        </Typography>

        <Typography className={styles.medium}>
          <span style={{color: "#44aa44"}}>Satellites</span> : {stats?.numSats ?? ''}
        </Typography>

        <Typography className={styles.medium}>
          Formats: {stats?.formatDetails?.split(',').join(', ') ?? ''}
        </Typography>

        <Typography className={styles.medium}>
          Systems: {stats?.navSystem ?? ''}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {chart}
      </Grid>
    </Grid>
  ;

  return <Card key={stream.id} className={cls}>
    <CardContent>

      <Typography className={styles.title}>
        <FontAwesomeIcon icon={faRightLeft} fixedWidth className={styles.icon} color={color}/>
        {stream.name}
      </Typography>

      <Typography className={clsx([styles.medium, styles.pad20])}>
        <FontAwesomeIcon icon={faEye} fixedWidth/> Last seen {stats?.start ? ago : 'never'}
        <br/>{stats?.start}
      </Typography>

      {info}

    </CardContent>

    <CardActions className={styles.actions}>
      <Link to={`/stream/edit/${stream.id}`}>
        <Button size="small">Edit</Button>
      </Link>
      {/*<Button disabled={disabled} size="small" onClick={handleDelete}>Delete</Button>*/}
    </CardActions>

  </Card>;
};
