import React from 'react';
import {Network, Station, UpdateNetworkInput} from '../../graphql';
import {UniversalCard} from '../Cards/UniversalCard';
import {NetworkForm} from '../Form/NetworkForm';
import {NetworkEditMap} from './map';
import {Button, Grid} from '@material-ui/core';
import {CardStyles} from '../Cards/CardStyles';

export interface NetworkEditViewProps {
  network?: Network;
  updateNetwork: Function;
  addStations: Function;
  removeStations: Function;
  stations: Station[];
}

export const NetworkEditView = (props: NetworkEditViewProps) => {
  const {network, updateNetwork, addStations, removeStations, stations} = props;
  const styles = CardStyles();
  const noStations = network?.stations?.length !== 0 ? <>Click to remove</> :
    <p>No stations in network</p>;

  const ids = network?.stations?.map((s) => s.id) ?? [];
  const newStations = stations.filter((s) => ids.indexOf(s.id) === -1);
  const noNewStations = newStations?.length !== 0 ? <>Click to add</> :
    <p>No remaining stations available to add</p>;

  const stationList = network?.stations
    ?.sort((a, b) => (a.name ?? "") < (b.name ?? "") ? -1 : 1)
    .map((s) =>
    <Button key={s.id} variant="outlined" style={{marginTop: 10, marginRight: 5}} onClick={() => {
      removeStations({stationIds: [s.id]})
    }}>{s.name}</Button>
  );

  const newStationList = newStations
    .sort((a, b) => (a.name ?? "") < (b.name ?? "") ? -1 : 1)
    .map((s) =>
    <Button key={s.id} variant="outlined" style={{marginTop: 10, marginRight: 5}} onClick={() => {
      addStations({stationIds: [s.id]})
    }}>{s.name}</Button>
  );

  return (
    <>
      <Grid container spacing={2} className={styles.pad10}>
        <Grid item md={6} xs={12}>
          <UniversalCard title="Edit Network">
            <NetworkForm network={network} onSubmit={(values: UpdateNetworkInput) => updateNetwork(values)}/>
          </UniversalCard>
        </Grid>
        <Grid item md={6} xs={12}>

          <UniversalCard title="Stations">
            {noStations}
            <div children={stationList}></div>
          </UniversalCard>

          <UniversalCard title="Available Stations">
            {noNewStations}
            <div children={newStationList}></div>
          </UniversalCard>

        </Grid>
      </Grid>

      <NetworkEditMap stations={network?.stations || []}/>
    </>
  );
};
