import {Network} from '../../graphql';
import {NetworkListView} from './view';

interface NetworkListContainerProps {
  networks: Array<Network>;
}

export const NetworkListContainer = (props: NetworkListContainerProps) => {
  return <NetworkListView networks={props.networks}/>;
};
