import {format} from "date-fns";
import {Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {Maybe, StationStats} from '../../graphql';

interface PositionChartProps {
  data: Maybe<StationStats>[];
}

interface ChartStats extends StationStats {
  epoch: number;
}

export const PositionChart = ({data}: PositionChartProps) => {
  let i = 0;
  const chartData = data.map((value: Maybe<StationStats>) => {
    // Decimate
    if (i++ % 10 !== 0) {
      return null;
    }

    const newValue = value as ChartStats;
    newValue.epoch = (new Date(newValue.start)).getTime();
    newValue.epoch = (new Date(newValue.start)).getTime();
    return newValue;
  }).filter(v => v !== null && v.epoch !== 0 && v.ecefX !== 0 && v.ecefY !== 0 && v.ecefZ !== 0);

  const dateFormatter = (date: number) => {
    return format(date, 'HH:mm');
  };

  return (
    <ResponsiveContainer>
    <LineChart
      style={{fontSize: 10, margin: 0}}
      width={100}
      height={100}
      data={chartData as any[]}
    >
      <XAxis height={20} dataKey="epoch" type="number" domain={['dataMin', 'dataMax']} scale="time" tickFormatter={dateFormatter}/>
      <YAxis yAxisId={111} width={8} dataKey="ecefX" orientation="left" domain={['dataMin', 'dataMax']} />
      <YAxis yAxisId={222} width={8} dataKey="ecefY" orientation="left" domain={['dataMin', 'dataMax']} />
      <YAxis yAxisId={333} width={8} dataKey="ecefZ" orientation="left" domain={['dataMin', 'dataMax']} />
      <Line yAxisId={111} type="monotone" dataKey="ecefX" stroke="#4444ff" dot={false}/>
      <Line yAxisId={222} type="monotone" dataKey="ecefY" stroke="#44ff44" dot={false}/>
      <Line yAxisId={333} type="monotone" dataKey="ecefZ" stroke="#ff4444" dot={false}/>
    </LineChart>
    </ResponsiveContainer>

  );
}
