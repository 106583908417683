import React from 'react';
import {Stream, UpdateStreamInput} from '../../graphql';
import {UniversalCard} from '../Cards/UniversalCard';
import {StreamForm} from '../Form/StreamForm';

interface StreamEditViewProps {
  stream: Stream;
  updateStream: Function;
}

export const StreamEditView = ({stream, updateStream}: StreamEditViewProps) => {
  return (
    <UniversalCard title="Edit Stream">
      <StreamForm stream={stream} onSubmit={(values: UpdateStreamInput) => {
        updateStream(values);
      }}/>
    </UniversalCard>
  );
};
