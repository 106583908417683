import {Fab, Grid} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Station} from '../../graphql';
import {CardStyles} from '../Cards/CardStyles';
import {StationCard} from '../Cards/StationCard';

interface StationListViewProps {
  stations: Array<Station>;
}

export const StationListView = ({stations}: StationListViewProps) => {
  const cs = CardStyles();

  return (
    <>

      <Link to="/station/new" className={cs.fabTop}>
        <Fab size="large" color="primary">Add</Fab>
      </Link>

      <Grid container spacing={2} className={cs.root}>
        {stations.map((station: Station) => (
          <Grid item md={4} xs={12} key={station.id}>
            <StationCard
              station={station}
              deleteStation={(id: string) => ''}/>
          </Grid>
        ))}
      </Grid>

    </>
  );
};
