import {Client, fetchExchange} from 'urql';
import {cacheExchange} from '@urql/exchange-graphcache';
import {apiUrl, login} from './components/Login/LoginStatusView';

const customFetch = (uri: RequestInfo, options?: RequestInit): Promise<Response> => {
  return fetch(uri, options).then(value => {
    if (value.status === 401) {
      login();
    }
    return value;
  });
};

export const client = new Client({
  url: apiUrl,
  exchanges: [cacheExchange({}), fetchExchange],
  fetch: customFetch,
});
