import {useNetworkListQuery} from '../../graphql';
import {NetworkListContainer} from './container';
import {ErrorView} from '../GraphQL/ErrorView';
import React from 'react';

export const NetworkListGraphQL = () => {
  const [networkListResult] = useNetworkListQuery({requestPolicy: 'network-only'});
  const {data, fetching, error} = networkListResult;
  const networks = data?.networks ?? [];

  return (
    <ErrorView fetching={fetching} errors={[error]}>
      <NetworkListContainer networks={networks}/>
    </ErrorView>
  );
};
