import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {UpdateNetworkInput, useCreateNetworkMutation} from '../../graphql';
import {NetworkNewContainer} from './container';
import {ErrorView} from '../GraphQL/ErrorView';
import {CombinedError} from 'urql';

export const NetworkNewGraphQL = () => {
  const history = useHistory();
  const [error, setError] = useState<CombinedError | undefined>();
  const [createNetworkResult, createNetworkPromise] = useCreateNetworkMutation();
  const createNetwork = (values: UpdateNetworkInput) => {
    createNetworkPromise({input: values}).then((result) => {
      if (result.error) {
        return setError(result.error);
      }
      history.replace('/network/edit/' + result?.data?.createNetwork?.result?.id);
    });
  }

  return (
    <ErrorView fetching={false} errors={[error]}>
      <NetworkNewContainer createNetwork={createNetwork}/>
    </ErrorView>
  );
};
