import React, {useContext} from 'react';
import {LayerContext} from './LayerContext';
import {useGetStationQuery} from '../../graphql';
import {StationCard} from '../Cards/StationCard';
import {ErrorView} from '../GraphQL/ErrorView';
import {makeStyles} from '@material-ui/core/styles';
import {StreamCard} from '../Cards/StreamCard';
import {Grid} from '@material-ui/core';

export const Styles = makeStyles((theme) => {
  return {
    overlay: {
      position: 'fixed',
      zIndex: 800,
      width: '100%',
      [theme.breakpoints.down(600)]: {
        margin: -15,
        top: '75%',
      }
    },
    grid: {
      position: 'fixed',
      display: 'block',
      height: '75%',
      overflow: 'auto',
      zIndex: 1234,
      pointerEvents: 'none'
    }
  }
});

export const StationCardOverlay = () => {
  const classes = Styles();
  const {node} = useContext(LayerContext);
  const [result, reexecuteQuery] = useGetStationQuery({
    variables: {id: node},
    pause: !node,
    requestPolicy: 'network-only',
  });

  const {data, fetching, error} = result;

  const streams = data?.station?.streams.map((s) =>
    <StreamCard
      key={`stream-${s.id}`}
      stream={s}
      lastChanged={s.stats[0].start}
      deleteStream={() => {
      }}
    />
  );

  const stationCard = !node || !data?.station ? <></> :
    <Grid container className={classes.grid}>
      <Grid item sm={6} md={4} lg={3}
            style={{pointerEvents: 'auto'}}
            className={classes.overlay}
            direction="column" spacing={1} container>

        <Grid item>
          <StationCard
            station={data?.station}
          >
          </StationCard>
        </Grid>

        <Grid item>
          {streams}
        </Grid>

      </Grid>
    </Grid>
  ;

  return !error ? stationCard :
    <ErrorView className={classes.overlay} fetching={fetching} errors={[error]}>
      <></>
    </ErrorView>
    ;
};
