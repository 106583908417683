import React from 'react';
import {Station} from '../../graphql';
import {StationEditView} from './view';

interface StationEditContainerProps {
  station: Station;
  updateStation: Function;
}

export const StationEditContainer = (props: StationEditContainerProps) => {
  return <StationEditView {...props} />;
};
