import React, {useMemo, useState} from 'react';
import {Stream} from '../../graphql';
import {StreamListView} from './view';
import {StationFilterForm, StationFilterFormValues} from '../Form/StationFilterForm';

interface StreamListContainerProps {
  streams: Array<Stream>;
}

export const StreamListContainer = (props: StreamListContainerProps) => {
  const [filters, setFilters] = useState<StationFilterFormValues>();
  const streams = !filters ? props.streams : props.streams.filter((v) => {
    const stats = v.stats ? v.stats[v.stats.length - 1] : null;
    if (!filters.disabled && !v.enabled) {
      return false;
    }
    return (
      (filters.offline && (!stats || stats.rate === 0)) ||
      (filters.online && (stats && stats.rate !== 0))
    );
  });

  return (
    <>
      <div style={{marginBottom: 10}}>
        <StationFilterForm name="StreamList" onChange={(values: StationFilterFormValues) => setFilters(values)}/>
      </div>
      <StreamListView streams={streams}/>
    </>
  );
};
