import {UniversalCard} from '../Cards/UniversalCard';
import {StationForm} from '../Form/StationForm';
import React from 'react';
import {CreateStationInput} from '../../graphql';

interface StationNewViewProps {
  createStation: Function;
}

export const StationNewView = ({createStation}: StationNewViewProps) => {
  return (
    <UniversalCard title="New Station">
      <StationForm onSubmit={(values: CreateStationInput) => createStation(values)}/>
    </UniversalCard>
  );
};
