import React from 'react';
import {Redirect, Route, Switch, useLocation, withRouter} from 'react-router-dom';
import {MainLayoutContainer} from './components/MainLayout';
import {NetworkEdit} from './components/NetworkEdit';
import {NetworkList} from './components/NetworkList';
import NetworkMap from './components/NetworkMap';
import {NetworkNew} from './components/NetworkNew';
import {StationEdit} from './components/StationEdit';
import {StationList} from './components/StationList';
import {StationNew} from './components/StationNew';
import {StreamEdit} from './components/StreamEdit';
import {StreamList} from './components/StreamList';
import {MainMenu} from './components/Menu/MainMenu';
import {LayerContextProvider} from './components/NetworkMap/LayerContext';
import {Login} from './components/Login';
import AppBar from '@material-ui/core/AppBar';
import {Grid} from '@material-ui/core';

function App() {
  const location = useLocation();
  const mainLayout =
    <MainLayoutContainer menu={<MainMenu/>} flex={location.pathname === '/map'}>
      <Switch>
        <Route exact path="/"><Redirect to="/map"/></Route>

        <Route exact path="/map">
          <LayerContextProvider>
            <NetworkMap/>
          </LayerContextProvider>
        </Route>

        <Route exact path="/networks"><NetworkList/></Route>
        <Route exact path="/network/new"><NetworkNew/></Route>
        <Route exact path="/network/edit/:id"><NetworkEdit/></Route>

        <Route exact path="/stations"><StationList/></Route>
        <Route exact path="/station/new"><StationNew/></Route>
        <Route exact path="/station/edit/:id"><StationEdit/></Route>

        <Route exact path="/streams"><StreamList/></Route>
        <Route exact path="/stream/edit/:id"><StreamEdit/></Route>

      </Switch>
    </MainLayoutContainer>
  ;

  return (
    <div className="App">
      <header className="App-header">
        {mainLayout}
      </header>
    </div>
  );
}

export default withRouter(App);
