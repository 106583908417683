import {Button, ButtonProps} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  refresh: {
    position: 'fixed',
    zIndex: 1000,
    background: 'white',
    minWidth: 0,
    right: 10,
    top: 80,
    borderRadius: 5,
    width: 48,
    height: 48,
    border: '2px solid rgba(0,0,0,0.2)',
    backgroundClip: 'padding-box',
    color: '#999',
  }
}));

export const MapButton = (props: ButtonProps) => {
  const classes = useStyles();
  return <Button className={classes.refresh} {...props} variant="outlined"/>;
};
