import {CreateStreamInput} from '../../graphql';
import {UniversalCard} from '../Cards/UniversalCard';
import {StreamForm} from '../Form/StreamForm';

interface StreamNewViewProps {
  createStream: Function;
}

export const StreamNewView = ({createStream}: StreamNewViewProps) => {
  return (
    <UniversalCard title="Add Data Stream">
      <StreamForm onSubmit={(values: CreateStreamInput) => createStream(values)}/>
    </UniversalCard>
  );
};
