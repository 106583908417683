import {useStationListQuery} from '../../graphql';
import {ErrorView} from '../GraphQL/ErrorView';
import {StationListContainer} from './container';
import React from 'react';

export const StationListGraphQL = () => {
  const [stationListResult] = useStationListQuery({requestPolicy: 'network-only'});
  const {data, fetching, error} = stationListResult;
  const stations = data?.stations ?? [];

  return (
    <ErrorView fetching={fetching} errors={[error]}>
      <StationListContainer stations={stations}/>
    </ErrorView>
  );
};
