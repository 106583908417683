import {faCircleNodes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, CardActions, CardContent, CardProps, Typography} from '@material-ui/core';
import React from 'react';
import {Link} from 'react-router-dom';
import {Network} from '../../graphql';
import {CardStyles} from './CardStyles';

interface NetworkCardProps extends CardProps {
  network: Network;
  deleteNetwork: Function;
}

export const NetworkCard = ({network, deleteNetwork, ...props}: NetworkCardProps) => {
  const classes = CardStyles();
  const disabled = !network.enabled;

  const handleDelete = () => {
    deleteNetwork(network.id);
  };

  const cls = [
    classes.card,
    // classes.fullwidth,
    disabled ? classes.disabled : '',
  ].join(' ');

  return <Card key={network.id} className={cls} {...props}>
    <CardContent>

      <Typography className={classes.title}>
        <FontAwesomeIcon icon={faCircleNodes} fixedWidth className={classes.icon}/>
        {network.name}
      </Typography>

      <Typography className={classes.info}>
        {network.id} | {network.createdAt} | {network.updatedAt}
      </Typography>
    </CardContent>

    <CardActions className={classes.actions}>
      <Link to={`/network/edit/${network.id}`}>
        <Button size="small">Edit</Button>
      </Link>
      {/*<Button disabled={disabled} size="small" onClick={handleDelete}>Delete</Button>*/}
    </CardActions>

  </Card>;
};
