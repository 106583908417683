import React from 'react';
import {CreateNetworkInput} from '../../graphql';
import {UniversalCard} from '../Cards/UniversalCard';
import {NetworkForm} from '../Form/NetworkForm';

interface NetworkNewViewProps {
  createNetwork: Function;
}

export const NetworkNewView = ({createNetwork}: NetworkNewViewProps) => {
  return (
    <UniversalCard title="New Network">
      <NetworkForm onSubmit={(values: CreateNetworkInput) => createNetwork(values)}/>
    </UniversalCard>
  );
};
