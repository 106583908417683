import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  AddStationsInput,
  QueryNetworkArgs,
  RemoveStationsInput,
  UpdateNetworkInput,
  useAddStationsMutation,
  useGetNetworkQuery,
  useRemoveStationsMutation,
  useStationListQuery,
  useUpdateNetworkMutation
} from '../../graphql';
import {NetworkEditContainer} from './container';
import {ErrorView} from '../GraphQL/ErrorView';
import {NetworkListContainer} from '../NetworkList/container';

export const NetworkEditGraphQL = () => {
  const history = useHistory();
  const {id} = useParams<QueryNetworkArgs>();
  const [result, reexecuteQuery] = useGetNetworkQuery({variables: {id}});
  const {data, fetching, error} = result;
  const [updateNetworkResult, updateNetworkPromise] = useUpdateNetworkMutation();
  const updateNetwork = (values: UpdateNetworkInput) => {
    updateNetworkPromise({input: {...values, id}}).then((result) => {
      history.push('/networks');
    })
  };
  const [stationListResult] = useStationListQuery();
  const {data: stationData} = stationListResult;
  const stations = stationData?.stations ?? [];
  const [addStationResult, addStationPromise] = useAddStationsMutation();
  const addStations = (values: AddStationsInput) => {
    addStationPromise({input: {...values, id}}).then((result) => {
    })
  }
  const [removeStationResult, removeStationPromise] = useRemoveStationsMutation();
  const removeStations = (values: RemoveStationsInput) => {
    removeStationPromise({input: {...values, id}}).then((result) => {
    })
  }

  return (
    <ErrorView fetching={fetching} errors={[error]}>
      <NetworkEditContainer
        network={data?.network ?? undefined}
        updateNetwork={updateNetwork}
        addStations={addStations}
        removeStations={removeStations}
        stations={stations}
      />
    </ErrorView>
  );
};
