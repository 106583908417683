import React from 'react';
import {StreamNewView} from './view';

interface StreamNewContainerProps {
  createStream: Function;
}

export const StreamNewContainer = (props: StreamNewContainerProps) => {
  return <StreamNewView {...props} />;
};
